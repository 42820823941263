// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-payments-dfgfadfc-tsx": () => import("./../src/pages/payments/dfgfadfc.tsx" /* webpackChunkName: "component---src-pages-payments-dfgfadfc-tsx" */),
  "component---src-pages-payments-iktstrlv-tsx": () => import("./../src/pages/payments/iktstrlv.tsx" /* webpackChunkName: "component---src-pages-payments-iktstrlv-tsx" */),
  "component---src-pages-payments-yfsl-0306-tsx": () => import("./../src/pages/payments/yfsl0306.tsx" /* webpackChunkName: "component---src-pages-payments-yfsl-0306-tsx" */),
  "component---src-pages-thanks-dfgfadfc-tsx": () => import("./../src/pages/thanks/dfgfadfc.tsx" /* webpackChunkName: "component---src-pages-thanks-dfgfadfc-tsx" */),
  "component---src-pages-thanks-iktstrlv-tsx": () => import("./../src/pages/thanks/iktstrlv.tsx" /* webpackChunkName: "component---src-pages-thanks-iktstrlv-tsx" */),
  "component---src-pages-thanks-yfsl-0306-tsx": () => import("./../src/pages/thanks/yfsl0306.tsx" /* webpackChunkName: "component---src-pages-thanks-yfsl-0306-tsx" */)
}

